import { gql } from "@apollo/client";
export const GET_USER = gql`
  query GetUser {
    user {
      email
      phone
      name
      activity
      access
      date
      markets
      alerts
      smsAlerts
      stripe
      _id
      activeByKey
    }
  }
`;

export const SubscribeToUser = gql`
  subscription SubscribeToUser {
    user {
      email
      phone
      name
      activity
      access
      date
      markets
      alerts
      smsAlerts
      stripe
      _id
      activeByKey
    }
  }
`;
export const DeleteUser = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;
export const Subscribe_User = gql`
  subscription GetUser {
    user {
      email
      phone
      name
      activity
      access
      date
      markets
      alerts
      smsAlerts
      stripe
      _id
      activeByKey
    }
  }
`;
export const GetUsers = gql`
  query Users {
    users {
      email
      phone
      name
      activity
      access
      date
      markets
      alerts
      smsAlerts
      stripe
      _id
      activeByKey
    }
  }
`;

export const UpdateUser = gql`
  mutation Mutation($id: ID!, $data: userInputType) {
    updateUser(id: $id, data: $data)
  }
`;
export const SubscribeUsers = gql`
  subscription SubscribeUsers {
    users {
      email
      phone
      name
      activity
      access
      date
      markets
      alerts
      smsAlerts
      stripe
      _id
    }
  }
`;

export const CreateRule = gql`
  mutation CreateRule($data: ruleInputType!) {
    createRule(data: $data)
  }
`;

export const UpdateRule = gql`
  mutation ($id: ID!, $data: ruleInputType!) {
    updateRule(id: $id, data: $data)
  }
`;
export const GetRules = gql`
  query GetRules {
    rules
  }
`;
export const DeleteRule = gql`
  mutation ($id: ID!) {
    deleteRule(id: $id)
  }
`;
export const RulesSubscription = gql`
  subscription RulesSubscription {
    rules
  }
`;
export const ManageBilling = gql`
  mutation ManageBilling($domain: String!) {
    manageBilling(domain: $domain)
  }
`;
export const DeleteAlert = gql`
  mutation DeleteAlert($id: ID!) {
    deleteAlert(id: $id)
  }
`;
export const UpdateAlert = gql`
  mutation UpdateAlert($id: ID!, $data: updateAlertInputType!) {
    updateAlert(id: $id,  data: $data)
  }
`;
export const TriggerAlert = gql`
  mutation TriggerAlert($id: ID!) {
    triggerAlert(id: $id)
  }
`;

export const TriggerOrsAlert = gql`
  mutation TriggerOrsAlert($price: String!, $symbol: String!) {
    triggerOrsAlert(price: $price, symbol: $symbol)
  }
`;
export const CreateAlert = gql`
  mutation CreateAlert($data: alertInputType!) {
    createAlert(data: $data)
  }
`;
export const CreateRangeAlert = gql`
  mutation CreateRangeAlert($data: rangeAlertInputType!) {
    createRangeAlert(data: $data)
  }
`;

export const GetAlerts = gql`
  query GetAlerts {
    alerts
  }
`;

export const SubscribeAlerts = gql`
  subscription SubscribeAlerts {
    alerts
  }
`;

export const UpdateUserSettingsChart = gql`
  mutation updateUserSettingsChart(
    $data: JSON!
    $symbol: String!
    $type: String!
  ) {
    updateUserSettingsChart(data: $data, symbol: $symbol, type: $type)
  }
`;

export const GetUserChartSettings = gql`
  query GetUserChartSettings {
    userSettingsChart
  }
`;

export const UpdateChartSettings = gql`
  mutation UpdateChartSettings($data: JSON!, $symbol: String!, $type: String!) {
    updateSettingsChart(data: $data, symbol: $symbol, type: $type)
  }
`;
export const GetChartSettings = gql`
  query GetChartSettings($type: String!) {
    settingsChart(type: $type)
    userSettingsChart(type: $type)
  }
`;
export const SubscribeMarkets = gql`
  subscription SubscribeMarkets {
    markets
  }
`;

export const SubscribeLiveStream = gql`
  subscription SubscribeLiveStream($type: String!) {
    live_stream(type: $type)
  }
`;

export const GetMarkets = gql`
  query GetMarkets($type: String!) {
    markets(type: $type)
  }
`;

export const GetKeys = gql`
  query GetKeys {
    keys
  }
`;
export const DeleteKey = gql`
  mutation DeleteKey($id: ID!) {
    deleteKey(id: $id)
  }
`;
export const SubscribeKeys = gql`
  subscription SubscribeKeys {
    keys
  }
`;
export const CreateKeys = gql`
  mutation CreateKeys($total: Int!) {
    createKey(total: $total)
  }
`;
export const SUBSCRIBE_WITH_KEY = gql`
  mutation SubscribeWithKey($key: String!) {
    subscribeProductWithKey(key: $key)
  }
`;
export const UpdateMarkets = gql`
  mutation UpdateMarkets($data: JSON!, $type: String!) {
    markets(data: $data, type: $type)
  }
`;

export const GET_Tutorials = gql`
  query GetTutorials($type: String!) {
    tutorials(type: $type)
  }
`;

export const CreateTutorial = gql`
  mutation CreateTutorial($data: tutorialInputType!, $type: String!) {
    createTutorial(data: $data, type: $type)
  }
`;

export const UpdateTutorial = gql`
  mutation UpdateTutorial($updateTutorialId: ID!, $data: tutorialInputType!) {
    updateTutorial(id: $updateTutorialId, data: $data)
  }
`;

export const DeleteTutorialVideo = gql`
  mutation DeleteTutorialVideo($id: ID!) {
    deleteTutorial(id: $id)
  }
`;

export const UpdateLiveStream = gql`
  mutation UpdateLiveStream($streamId: String!, $type: String!) {
    updateLiveStream(stream_id: $streamId, type: $type)
  }
`;

export const GetLiveStream = gql`
  query GetLiveStream($type: String!) {
    live_stream(type: $type)
  }
`;
export const GetClientSecret = gql`
  query GetClientSecret {
    clientSecret
  }
`;
export const USER_SUBSCRIPTION = gql`
  subscription userSubscription {
    user {
      email
      name
      stripe
      _id
      key
      activeByKey
    }
  }
`;

export const SUBSCRIBE_PRODUCT = gql`
  mutation SubscribeProduct(
    $ids: [String]!
    $domain: String!
    $trial: Boolean!
  ) {
    subscribeProduct(ids: $ids, domain: $domain, trial: $trial)
  }
`;
export const GetProductsAndUser = gql`
  query GetProductsAndUser {
    products
    user {
      _id
      email
      stripe
      key
      activeByKey
    }
  }
`;

export const AddItemToSubscription = gql`
  mutation AddItemToSubscription(
    $subscription_id: String!
    $price_id: String!
  ) {
    addItemToSubscription(
      subscription_id: $subscription_id
      price_id: $price_id
    )
  }
`;

export const RemoveItemFromSubscription = gql`
  mutation RemoveItemFromSubscription($subscription_item_id: String!) {
    removeItemFromSubscription(subscription_item_id: $subscription_item_id)
  }
`;
